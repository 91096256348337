import React from 'react';
import * as moment from 'moment';

class Message extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : props.user,
            message : props.message,
        }
    }
    
    componentWillReceiveProps(props) {
        this.setState({
            user : props.user,
            message : props.message,
        })
    }
    render() {
        const {
            message,
            user
        } = this.state

        return (
            <div className={`message-${user.id === message.author.id ? "right" : "left"}`}>
                {/* {message.author.name}<br /> */}
                <div class='message-body'>
                    {message.body}
                </div>
                <div class="message-timestamp">
                    {timestampToString(message.createdAt)} 
                </div>
            </div>
        )
    }
}


function timestampToString(timestamp) {
    moment.locale('en')
    return moment(timestamp).format('M/D/YYYY h:mm A')
}
export default Message;