import React from 'react';
import ReactDOM from 'react-dom';
import ChatRoom from './chatroom/ChatRoom'
import ChatList from './chatroom/ChatList'
import { firebase, chatroom } from '../firebase'
import SendMessageForm from './SendMessageForm';

class ChatPage extends React.Component {

  constructor(props) {
    super(props);
    var user = {}
    let uid = firebase.auth.currentUser.uid
    if (uid === "qu2rruQ66tU59i7FSwuCZrj8g8k1") {
      user = {
        id : "bestmoving",
        name : "Best Moving Service"
      }
    } else if (uid === "lKiqcoQ90zTFMjbyFctVne9rP9s2") {
      user = {
        id : "bestqualitymovers",
        name : "Best Quality Movers"
      }
    } else if (uid === "XZGeV4cyN4UnFCbeFuQLjGOJz1a2") {
      user = {
        id : "sterling",
        name : "Sterling Van Lines"
      }
    }
    this.state = {
        user: user,
        chats: {},
        selectedChatMessages: [],
        selectedConvo: null
    };
  }

  componentDidMount() {
    this.listenForConversations()
  }

  getCurrentConversations() {
    const user = this.state.user
    firebase.database.ref("conversations").orderByChild("mover").equalTo(user.id).once("value", (snap, error) => {
      var convo = snap.val()
      convo.id = snap.id
      let index = this.state.chats.findIndex((existing) => { return convo.id === existing.id })
      if (index === undefined || index === null) {
        // convo does not exist yet
        this.state.chats.push(convo)
        this.state.chats.sort((first, last) => { return first.lastMessage.createdAt >= last.lastMessage.createdAt })
        this.setState({
          chats : this.state.chats
        })
      } else {
        this.state.chats[index] = convo
        this.setState({
          chats : this.state.chats
        })
      }
    })
  }

  getChatsInOrder() {
    let chats = Object.values(this.state.chats)
    chats.sort((first, last) => { return first.lastMessage.createdAt <= last.lastMessage.createdAt })
    return chats
  }

  getSelectedChatMessagesInOrder() {
    if (this.state.selectedChatMessages === null || this.state.selectedChatMessages === undefined) { return [] }
    return this.state.selectedChatMessages
  }

  listenForConversations() {
    firebase.database.ref("conversations").orderByChild("mover").equalTo(this.state.user.id).on("value", (snap, error) => {
      console.log("New Conversation")
      var convos = snap.val()
      Object.keys(convos).forEach(convoId => {
        convos[convoId].id = convoId
      })
      this.setState({
        chats : convos
      }, () => {})
    })
  }

  listenToConversation(convoId) {
    chatroom.getMessages(convoId)
  }

  didSelectConvo(convo) {
    if (this.state.selectedConvo !== undefined && this.state.selectedConvo !== null) {
      // turn off previous listener
      firebase.database.ref(`conversations/${this.state.selectedConvo.id}`).off()
    }
    // listen for new messages
    firebase.database.ref(`conversations/${convo.id}`).on("value", (snap,error) => {
      this.state.selectedConvo = snap.val()
      this.state.selectedConvo.id = snap.key
      this.state.chats[this.state.selectedConvo.id] = this.state.selectedConvo
      chatroom.getMessages(this.state.selectedConvo.id, null, 200)
      .then(messages => {
        console.log("retreived messages")
        console.log(messages)
        this.setState({ 
          selectedChatMessages : messages
        }, () => { console.log(this.state.selectedChatMessages) })
      })
      // TODO: Error checking
      this.setState({
        selectedConvo: this.state.selectedConvo,
        chats: this.state.chats
      }, () => { 
        console.log("state was set")
        console.log(this.state) 
      })
    })
  }
  
  sendMessage(message) {
    if (this.state.selectedConvo !== undefined && this.state.selectedConvo !== null) {
      chatroom.sendMessage(this.state.selectedConvo.id, this.state.user, message , null);
    }
  }

  submitMessage(e) {
    e.preventDefault();

    this.setState({
        
    }, () => {
        let body = ReactDOM.findDOMNode(this.refs.msg).value
        ReactDOM.findDOMNode(this.refs.msg).value = "";
        this.sendMessage(body)
    });
  }

  render() {
    const {
      user
    } = this.state
    const selectedId = this.state.selectedConvo !== null && this.state.selectedConvo !== undefined ? this.state.selectedConvo.id : ""
    return (
      <div className="chat" ref="chat">
        <ul className="chatlist" ref="chatlist">
          <ChatList chats={this.getChatsInOrder()} selectedId={selectedId} user={user} didSelectConvo={this.didSelectConvo.bind(this)}/>
        </ul>
        <ul className="chatroom" ref="chatroom">
          <ChatRoom user={user} messages={this.getSelectedChatMessagesInOrder()} sendMessage={this.sendMessage.bind(this)}/>
        </ul>
        <div className="message-input">
          <SendMessageForm onSend={(e) => this.sendMessage(e)} />
        </div>
      </div>
    )
  }

}
  

export default ChatPage;