import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';
import * as firebase from '../../firebase'
import * as actions from '../../actions'

import Message from '../Message/Message.js';
import { database } from 'firebase';

const user = {
    id : "PGBG86WmnUfuweFzhM38UquzN502",
    name : "Michael"
}

const convo = {
    id : "-LP8ZjJACe_iMWENcG1T"
}
class Chatroom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            messages: props.messages
        };
    }
    
    componentWillReceiveProps(newProps) {
        this.setState(newProps)
    }

    componentDidMount() {
        this.scrollToBot()
        // this.getMessages()
        // this.listenForMessages()
    }

    componentWillReceiveProps(newProps) {
        if (newProps.messages !== null && newProps.messages !== undefined)
        this.setState({
            user: newProps.user,
            messages: newProps.messages
        })
        this.scrollToBot();
    }

    componentDidUpdate() {
        this.scrollToBot();
    }

    scrollToBot() {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    render() {
        const { user, messages } = this.state;
        return (
            <div className="chatroom">
                <ul className="chats" ref="chats">
                    {
                        messages.map((chat) =>
                            <Message message={chat} user={user} />
                        )
                    }
                </ul>

                <div style={{ float:"left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </div>
        );
    }
}

export default Chatroom;