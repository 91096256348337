import React from 'react'
import * as moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
});

const StyledListItemText = withStyles({
    root: {
      color: 'white',
    },
    label: {
      textTransform: 'capitalize',
    },
  })(ListItemText);
  
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

class ChatList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user : props.user,
            chats : [],
            selectedId : props.selectedId
        }
    }

    componentDidMount () {
    }

    didSelectConvo (convo) {
        this.props.didSelectConvo(convo)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.chats !== this.state.chats && nextProps.chats !== null && nextProps.chats !== undefined) {
            this.setState( {
                chats : nextProps.chats,
                selectedId : nextProps.selectedId
            })
        }
      }

    render () {
        const { user, chats, selectedId } = this.state
        return (
            <div>
                {
                    <List component="nav"> {
                        chats.map((chat) => 
                            <ListItem className={`chatlist-entry${selectedId === chat.id ? "-selected" : ""}`} divider button selected={selectedId === chat.id } onClick={() => { this.didSelectConvo(chat) }}>
                                <StyledListItemText className={`chatlist-entry-name`} primary={chat.userName}/>
                                <StyledListItemText className={`chatlist-entry-timestamp`} primary={this.timestampToString(chat.lastMessage.createdAt)}/>
                            </ListItem>
                        )
                    }</List>
                }
            </div> 
        )
    }

    timestampToString(timestamp) {
        moment.locale('en')
        return moment(timestamp).format('MMM D')
    }
}

export default ChatList