
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
// Firebase config
const config = {
apiKey: "AIzaSyCQh4B-cmN_Xj-BknIzl1VGkrcdVknuTmg",
authDomain: "muvn-4c4d5.firebaseapp.com",
databaseURL: "https://muvn-4c4d5.firebaseio.com",
projectId: "muvn-4c4d5",
storageBucket: "muvn-4c4d5.appspot.com",
messagingSenderId: "472824492928"
};

if(!firebase.apps.length) {
    firebase.initializeApp(config);
}

const auth = firebase.auth();
const database = firebase.database();

export {
    auth,
    database,
};