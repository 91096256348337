import { database } from './firebase';
import { auth } from '.';
import firebase from 'firebase'

// Get Messages 
/**
 * 
 * @param {String} conversationId 
 * @param {Int} start 
 * @param {Int} end 
 * 
 * @returns {[Message]}
 */

 /**
  * 
  * @param {*} conversationId 
  * @param {*} last 
  * @param {*} limit 
  * 
  * @return {Promise<[Message]>}
  */
export const getMessages = (conversationId, last, limit) => {
    return new Promise((resolve, reject) => {
        database.ref(`conversations/${conversationId}/messages`).orderByChild('createdAt').limitToLast(limit)
        .once("value", function(snap, error) {
            var value = []
            snap.forEach(function(message) {
                console.log(message.val())
                value.push(message.val())
            })
            resolve(value) 
        })
    }) 
}

export const listenToMessages = (conversationId) => {
    return new Promise((resolve, reject) => {
        database.ref('conversations/' + conversationId + '/messages').on('child_added', function(child,error) {
            console.log('Child was: ')
            console.log(child.val())
            return resolve(child)
        })
    })
}

/**
 * 
 * @param {String} convoId id of conversation
 * @param {User} author should have fields id, and name 
 * @param {String} body string with the message of the body 
 * @param {URL} img url to image 
 */
export const sendMessage = (convoId, author, body, img) => {
    console.log('Sending Message: \n' + body)
    let timestamp = firebase.database.ServerValue.TIMESTAMP
    let messageId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    let message = {
        author : author,
        body : body,
        createdAt: timestamp,
        id: messageId
    }
    database.ref('conversations/' + convoId + '/messages/' + messageId).set(message, function(error) {
        console.log(error)
        return error
    })
}
